import React from "react";
import update from "immutability-helper";

const locale = process.env.REACT_APP_LOCALE || "ko";
export const SESSION_KEY = "qb_session";
const DEFAULT_HOST = "https://dev-api.quebon.tv";
const PROD_HOST = "https://api.quebon.tv";

export function getHost() {
  const host = process.env.REACT_APP_API_HOST;
  return DEFAULT_HOST;
  //return PROD_HOST;
  return host != null ? host : DEFAULT_HOST;
}

export function getMainHost() {  // for queture and quiz
  const host = process.env.REACT_APP_MAIN_API_HOST;
  return host != null ? host : getHost();
}

export function getToken() {
  const session = localStorage.getItem(SESSION_KEY);
  return session ? JSON.parse(session).token : null;
}

export function getDefaultHeader() {
  const token = getToken();

  return {
    Authorization: token != null ? "Bearer " + token : null
  };
}

export function getDefaultConfig(headers) {
  // if (token != null) {
  const _token = getToken();
  var config = {
    withCredentials: false,
    headers: {
      Pragma: "no-cache",
      Authorization: _token != null ? "Bearer " + _token : null,
      "x-qb-client-id": window.clientId
    }
  };
  // "Accept-Language": locale === 'en' ? 'en' : null,
  if (locale === "en") {
    config.headers["Accept-Language"] = "en";
  }

  if (headers != null) {
    config = update(config, {
      headers: {
        $merge: headers
      }
    });
  }

  return config;

  // } else {
  //     return {
  //         withCredentials: true
  //     }
  // }
}

export function parseAxiosError(error, from) {
  if (error == null) {
    return {
      triggered: false,
      from: from,
      status: -1
    };
  }

  if (error.response != null) {
    return {
      triggered: true,
      from: from,
      status: error.response.status,
      code: error.response.data.code,
      message: error.response.data.message,
      parameter: error.response.data.parameter
    };
  } else if (error.customData != null) {
    console.log(error.customData);
    return {
      ...error.customData,
      from: from,
      triggered: true
    };
  } else {
    console.log(error);
    return {
      triggered: true,
      from: from,
      status: -1
    };
  }
}
